// IMPORTS
import './Login.scss';

import $ from 'jquery';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import useAuthToken from '../../hooks/useAuthToken';


// COMPONENT
function Login () {
    // Security
    const [authToken, setAuthToken] = useAuthToken();


    // States and Variables
    document.title = "Login | Request a Plex Item";
    const defaultStates = {
        password: "",
        shLoginMessage: false,
        loginBtn: true,
        loginMessage: "Logging in, please wait...",
        loginColor: "yellow"
    };
    const [states, setStates] = useState(defaultStates);

    // Redirections
    const [redirects, setRedirects] = useState({
        loginSuccessful: false
    });
    if (redirects.loginSuccessful) {
        return (<Navigate to='/admin'/>);
    }


    // Functions
    function submitLogin () {
        $.ajax({
            url: "/services/Login/submit_login.py",
            method: "POST",
            data: {
                token: authToken,
                password: states.password
            },
            dataType: "json"
        }).done(function (data) {
            if (data.status == "success") {
                setRedirects({...redirects, loginSuccessful: true});
            } else {
                setStates({...states, shLoginMessage: true, loginMessage: data.message, loginColor: "red", loginBtn: false});
            }
        }).fail(function () {
            setStates({...states, shLoginMessage: true, loginMessage: "An error occurred. Please try again!", loginColor: "red", loginBtn: false});
        });
    }


    // Component
    return (
        <div className="Login">
            <input className='global-input' type='password' value={states.password} placeholder='Password' onChange={(element) => {
                if (element.target.value) {
                    setStates({...states, password: element.target.value, loginBtn: false});
                } else {
                    setStates({...states, password: "", loginBtn: true});
                }
            }} />
            <button className='global-btn' disabled={states.loginBtn} onClick={() => {
                setStates({...states, loginBtn: true, loginMessage: "Logging in, please wait...", loginColor: "yellow", shLoginMessage: true});
                submitLogin();
            }}>Login</button>
            {states.shLoginMessage && <p style={{'color': states.loginColor}}>{states.loginMessage}</p>}
        </div>
    );
}

export default Login;