// IMPORTS
import './Home.scss';

import { useEffect, useState } from 'react';
import $ from 'jquery';


// COMPONENT
function Home () {
    // States and Variables
    document.title = "Request a Plex Item";
    const [years, setYears] = useState(null);
    const defaultStates = {
        type: "",
        title: "",
        year: new Date().getFullYear(),
        email: "",
        shStep1: true,
        shStep2: false,
        shStep3: false,
        shStep4: false,
        shStep5: false,
        shStep6: false,
        shStep7: false,
        step2Btn: true,
        step5Btn: true,
        statusMessage: "Submitting request, please wait...",
        statusColor: "yellow"
    };
    const [states, setStates] = useState(defaultStates);
    const [requests, setRequests] = useState(null);
    

    // On-load
    useEffect(function () {
        // Retrieve Current Requests
        getRequests();

        // Build Years for Selector
        var temp = [];
        var currentyear = new Date().getFullYear();
        for (var i = currentyear + 5; i >= 1900; i--) {
            temp.push(i);
        }
        setYears(temp);
    }, []);


    // Functions
    function checkEmail (email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    function getRequests () {
        $.ajax({
            url: "/services/Home/get_requests.py",
            method: "GET",
            dataType: "json"
        }).done(function (data) {
            setRequests(data.payload);
        }).fail(function () {
            console.log("bad return");
        });
    }
    function submitRequest () {
        $.ajax({
            url: "/services/Home/submit_request.py",
            method: "POST",
            data: {
                type: states.type,
                title: states.title,
                year: states.year,
                email: states.email
            },
            dataType: "json"
        }).done(function (data) {
            if (data.status == "success") {
                setStates({...states, statusMessage: "Request submitted!", statusColor: "green", shStep6: false, shStep7: true});
                setRequests([data.payload, ...requests]);
            } else {
                setStates({...states, statusMessage: "Request failed to submit!", statusColor: "red", shStep6: false, shStep7: true});
            }
        }).fail(function () {
            setStates({...states, statusMessage: "Request failed to submit!", statusColor: "red", shStep6: false, shStep7: true});
        }).always(function () {
            setTimeout(function () {
                setStates(defaultStates);
            }, 2000);
        });
    }
    function filterRequests (input) {
        $(".Home-requests-item").each(function () {
            let string = input.target.value.toLowerCase();
            let name = $(this).data('filter').toLowerCase();
            if (name.includes(string)) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
    }


    // Component
    return (
        <div className='Home'>
            <div className='Home-form'>
                {states.shStep1 && <div className='Home-form-container Home-form-step1'>
                    <h1>Request a</h1>
                    <div className='Home-form-options'>
                        <div onClick={() => {
                            setStates({...states, type: "movie", shStep1: false, shStep2: true});
                        }}>
                            <h1>Movie</h1>
                        </div>
                        <div onClick={() => {
                            setStates({...states, type: "tvshow", shStep1: false, shStep2: true});
                        }}>
                            <h1>TV Show</h1>
                        </div>
                    </div>
                </div>}
                {states.shStep2 && <div className='Home-form-container Home-form-step2'>
                    <h1>Title of the {states.type == "movie" ? "Movie" : "TV Show"}</h1>
                    <input className='global-input' id='Home-form-step2-title' type='text' value={states.title} placeholder='Title' onChange={(element) => {
                        if (element.target.value) {
                            setStates({...states, title: element.target.value, step2Btn: false});
                        } else {
                            setStates({...states, title: "", step2Btn: true});
                        }
                    }} />
                    <div className='Home-form-buttons'>
                        <button className='global-btn' onClick={() => {
                            setStates({...states, shStep1: true, shStep2: false});
                        }}>Back</button>
                        <button className='global-btn-success' disabled={states.step2Btn} onClick={() => {
                            setStates({...states, shStep2: false, shStep3: true});
                        }}>Next</button>
                    </div>
                </div>}
                {states.shStep3 && <div className='Home-form-container Home-form-step3'>
                    <h1>Initial Release Year</h1>
                    <select className='global-input' value={states.year} onChange={(element) => {
                        setStates({...states, year: element.target.value});
                    }}>
                        {years && years.map(function (year, index) {
                            return (
                                <option key={`year-${index}`} value={year}>{year}</option>
                            );
                        })}
                    </select>
                    <div className='Home-form-buttons'>
                        <button className='global-btn' onClick={() => {
                            setStates({...states, shStep2: true, shStep3: false});
                        }}>Back</button>
                        <button className='global-btn-success' disabled={states.step2Btn} onClick={() => {
                            setStates({...states, shStep3: false, shStep4: true});
                        }}>Next</button>
                    </div>
                </div>}
                {states.shStep4 && <div className='Home-form-container Home-form-step4'>
                    <div>
                        <h1>Do You Want to Receive a Completion Email?</h1>
                        <h2>Emails will be sent from <span>plexrequestwebapp@gmail.com</span></h2>
                    </div>
                    <div className='Home-form-options'>
                        <div onClick={() => {
                            setStates({...states, shStep4: false, shStep5: true});
                        }}>
                            <h1>Yes</h1>
                        </div>
                        <div onClick={() => {
                            setStates({...states, email: "", shStep4: false, shStep6: true});
                        }}>
                            <h1>No</h1>
                        </div>
                    </div>
                    <div className='Home-form-buttons'>
                        <button className='global-btn' onClick={() => {
                            setStates({...states, shStep3: true, shStep4: false});
                        }}>Back</button>
                    </div>
                </div>}
                {states.shStep5 && <div className='Home-form-container Home-form-step5'>
                    <input className='global-input' id='Home-form-step2-title' type='email' value={states.email} placeholder='Email Address' onChange={(element) => {
                        if (element.target.value) {
                            if (checkEmail(element.target.value)) {
                                setStates({...states, email: element.target.value, step5Btn: false});
                            } else {
                                setStates({...states, email: element.target.value, step5Btn: true});
                            }
                        } else {
                            setStates({...states, email: "", step5Btn: true});
                        }
                    }} />
                    <div className='Home-form-buttons'>
                        <button className='global-btn' onClick={() => {
                            setStates({...states, shStep4: true, shStep5: false});
                        }}>Back</button>
                        <button className='global-btn-success' disabled={states.step5Btn} onClick={() => {
                            setStates({...states, shStep5: false, shStep6: true});
                        }}>Next</button>
                    </div>
                </div>}
                {states.shStep6 && <div className='Home-form-container Home-form-step6'>
                    <h1>Is this Info Correct?</h1>
                    <h3>Type: <span>{states.type == "movie" ? "Movie" : "TV Show"}</span></h3>
                    <h3>Title: <span>{states.title}</span></h3>
                    <h3>Year: <span>{states.year}</span></h3>
                    <h3>Email: <span>{states.email ? states.email : "--none--"}</span></h3>
                    <div className='Home-form-buttons'>
                        <button className='global-btn-error' onClick={() => {
                            setStates(defaultStates);
                        }}>Start Over</button>
                        <button className='global-btn' onClick={() => {
                            if (states.email) {
                                setStates({...states, shStep5: true, shStep6: false});
                            } else {
                                setStates({...states, shStep4: true, shStep6: false});
                            }
                        }}>Back</button>
                        <button className='global-btn-success' onClick={() => {
                            setStates({...states, shStep6: false, shStep7: true});
                            submitRequest();
                        }}>Submit</button>
                    </div>
                </div>}
                {states.shStep7 && <div className='Home-form-container Home-form-step7'>
                    <p style={{'color': `var(--status_${states.statusColor})`}}>{states.statusMessage}</p>
                </div>}
            </div>
            <div className='Home-requests'>
                <h1>Active Requests</h1>
                <input type='text' className='global-input' placeholder='Filter Requests' onChange={(element) => {filterRequests(element);}} />
                <div className='Home-requests-items'>
                    {requests ? requests.length > 0 ? requests.map(function (request, index) {
                        return (
                            <div key={`request-${index}`} className="Home-requests-item" data-filter={request.title} style={{'background': `linear-gradient(to top, var(--status_${request.color_class}) ${request.progress}%, rgba(255, 255, 255, 0.55) ${request.progress}%)`}}>
                                <h1>{request.title}</h1>
                                <small>{request.year} - {request.content_type == "movie" ? "Movie" : "TV Show"}</small>
                                <h2>{request.name}</h2>
                                <p>{request.comments}</p>
                            </div>
                        );
                    }) : <h6>No requests have been made</h6> : <h5>Loading requests...</h5>
                    }
                </div>
            </div>
        </div>
    );
}

export default Home;