// IMPORTS
import './Admin.scss';

import { Navigate } from 'react-router-dom';
import useAuthToken from '../../hooks/useAuthToken';
import { useState, useEffect } from 'react';
import $ from 'jquery';


// COMPONENT
function Admin () {
    // States and Variables
    document.title = "Admin Dashboard | Request a Plex Item";
    const defaultStates = {
        shRequests: true,
        shReports: false,
        shTVShowIDs: false,
        activeRequest: 0
    };
    const [states, setStates] = useState(defaultStates);
    const [requests, setRequests] = useState({});
    const [statuses, setStatuses] = useState({});


    // On-load
    useEffect(function () {
        // Retrieve Current Requests
        getRequests();

        // Retrieve Current Statuses
        getStatuses();
    }, []);


    // Security
    const [authToken, setAuthToken, clearAuthToken] = useAuthToken();
    if (!authToken) {
        return (<Navigate to='/login'/>);
    }


    // Functions
    function getRequests () {
        $.ajax({
            url: "/services/Admin/get_requests.py",
            method: "POST",
            data: {
                token: authToken
            },
            dataType: "json"
        }).done(function (data) {
            if (data.status == 'success') {
                setRequests(data.payload);
            } else if (data.status == 'error') {

            } else if (data.status == 'noauth') {
                clearAuthToken();
            }
        }).fail(function () {
            console.log("bad return");
        });
    }
    function getStatuses () {
        $.ajax({
            url: "/services/Admin/get_statuses.py",
            method: "POST",
            data: {
                token: authToken
            },
            dataType: "json"
        }).done(function (data) {
            if (data.status == 'success') {
                setStatuses(data.payload);
            } else if (data.status == 'error') {

            } else if (data.status == 'noauth') {
                clearAuthToken();
            }
        }).fail(function () {
            console.log("bad return");
        });
    }
    function submitComment (comment) {
        $.ajax({
            url: "/services/Admin/submit_comment.py",
            method: "POST",
            data: {
                token: authToken,
                requestID: requests[states.activeRequest].ID,
                comment: comment
            },
            dataType: "json"
        }).done(function (data) {
            if (data.status == 'success') {
                
            } else if (data.status == 'error') {

            } else if (data.status == 'noauth') {
                clearAuthToken();
            }
        }).fail(function () {
            console.log("bad return");
        });
    }
    function changeStatus (status) {
        $.ajax({
            url: "/services/Admin/change_status.py",
            method: "POST",
            data: {
                token: authToken,
                requestID: requests[states.activeRequest].ID,
                statusID: status
            },
            dataType: "json"
        }).done(function (data) {
            if (data.status == 'success') {
                
            } else if (data.status == 'error') {

            } else if (data.status == 'noauth') {
                clearAuthToken();
            }
        }).fail(function () {
            console.log("bad return");
        });
    }
    function deleteRequest () {
        $.ajax({
            url: "/services/Admin/delete_request.py",
            method: "POST",
            data: {
                token: authToken,
                requestID: requests[states.activeRequest].ID
            },
            dataType: "json"
        }).done(function (data) {
            if (data.status == 'success') {
                getRequests();
            } else if (data.status == 'error') {

            } else if (data.status == 'noauth') {
                clearAuthToken();
            }
        }).fail(function () {
            console.log("bad return");
        });
    }


    // Component
    return (
        <div className="Admin">
            <div className='Admin-nav'>
                <div onClick={() => {
                    setStates({...states, shRequests: true, shReports: false, shTVShowIDs: false});
                }}>
                    <img src='/assets/icons/checklist.svg' />
                    <h1>Requests</h1>
                </div>
                <div onClick={() => {
                    setStates({...states, shRequests: false, shReports: true, shTVShowIDs: false});
                }}>
                    <img src='/assets/icons/books.svg' />
                    <h1>Reports</h1>
                </div>
                <div onClick={() => {
                    setStates({...states, shRequests: false, shReports: false, shTVShowIDs: true});
                }}>
                    <img src='/assets/icons/key.svg' />
                    <h1>TV Show IDs</h1>
                </div>
            </div>
            {states.shRequests && <div className='Admin-content Admin-requests'>
                <div className='Admin-content-header'>
                    <h1>Requests</h1>
                </div>
                <div className='Admin-requests-content'>
                    <div className='Admin-requests-list'>
                        {requests ? requests.length > 0 ? requests.map(function (request, index) {
                            return (
                                <div key={`request-${index}`} className='Admin-requests-listitem' onClick={() => {
                                    setStates({...states, activeRequest: index});
                                }}>
                                    <div style={{backgroundColor: `var(--status_${request.status_color})`}}></div>
                                    <h1>{request.title}</h1>
                                </div>
                            );
                        }) : <h1>No requests have been made</h1> : <h1>Loading requests...</h1>}
                    </div>
                    {requests ? requests.length > 0 ? requests.map(function (request, index) {
                        if (index == states.activeRequest) {
                            return (
                                <div key={`requestdetail-${index}`} className='Admin-requests-details'>
                                    <div>
                                        <h1>Title:</h1>
                                        <p>{requests[states.activeRequest].title}</p>
                                    </div>
                                    <div>
                                        <h1>Type:</h1>
                                        <p>{requests[states.activeRequest].content_type == "movie" ? "Movie" : "TV Show"}</p>
                                    </div>
                                    <div>
                                        <h1>Year:</h1>
                                        <p>{requests[states.activeRequest].year}</p>
                                    </div>
                                    <div>
                                        <h1>Google Search:</h1>
                                        <button className='global-btn' onClick={() => {
                                            window.open('https://www.google.com/search?q=' + requests[states.activeRequest].title + '+' + requests[states.activeRequest].year, '_blank');
                                        }}>Go!</button>
                                    </div>
                                    <div>
                                        <h1>Date Submitted:</h1>
                                        <p>{requests[states.activeRequest].date_submitted}</p>
                                    </div>
                                    <div>
                                        <h1>Receive Email:</h1>
                                        <p>{requests[states.activeRequest].email ? "Yes" : "No"}</p>
                                    </div>
                                    <div>
                                        <h1>Comments:</h1>
                                        <input type="text" className='global-input' defaultValue={requests[states.activeRequest].comments} onBlur={(element) => {
                                            const temp = requests;
                                            temp[states.activeRequest].comments = element.target.value;
                                            setRequests(temp);
                                            submitComment(element.target.value);
                                        }}/>
                                    </div>
                                    <div className='Admin-requests-statusgroup'>
                                        <h1>Status:</h1>
                                        {statuses && statuses.length > 0 && statuses.map(function (status, index) {
                                            return (
                                                <div key={`requests-status-${index}`}>
                                                    <input name='requests-statusgroup' type='radio' className='global-input' defaultChecked={status.ID == requests[states.activeRequest].status_ID} onClick={() => {
                                                        const temp = requests;
                                                        temp[states.activeRequest].status_ID = status.ID;
                                                        setRequests(temp);
                                                        changeStatus(status.ID);
                                                    }}/>
                                                    <p style={{color: `var(--status_${status.color_class})`}}>{status.name}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div>
                                        <h1>Delete Entry:</h1>
                                        <button className='global-btn-error' onClick={() => {
                                            deleteRequest();
                                        }}>Delete</button>
                                    </div>
                                </div>
                            )
                        }
                    }) : <h1>No Details Selected</h1> : <h1>No Details Selected</h1>}
                </div>
            </div>}
            {states.shReports && <div className='Admin-content Admin-reports'>
                <div className='Admin-content-header'>
                    <h1>Reports</h1>
                </div>
            </div>}
            {states.shTVShowIDs && <div className='Admin-content Admin-tvshowids'>
                <div className='Admin-content-header'>
                    <h1>TV Show IDs</h1>
                </div>
            </div>}
        </div>
    );
}

export default Admin;